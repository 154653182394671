import instance from "../../axios.js";
import get from "lodash/get";
import { ReducersTypes } from "../../constants/reducerTypes.js";
import { generateQueryString } from "../../utils/query.js";
import toast from "react-hot-toast";

const setData = (type, data) => ({
  type,
  payload: {
    data,
  },
});

export const exportData = (params) => async (dispatch) => {
  let URL = await getQuery(params, "customer?is_export=1");
  dispatch(setData(ReducersTypes.SET_CUSTOMER_LIST, { exporting: true }));
  return instance.get(URL);
};

export const exportInvoiceData = (params) => async (dispatch) => {
  params.include_lines=1
  params.is_export=1
  params.status="all"
  const new_params =  {'invoice_type': 'invoice', 'include_lines': 1, 'is_export': 1, 'status': "all", from_date: params.from_date, to_date: params.to_date}
  // {'include_lines': 1, 'is_export': 1, 'status': "all"}
  let URL = await getQuery(new_params, "invoice");
  console.log(`\n#######################\n\n INVOICE EXPORT QUERY:\n ${URL}\n\n#############################\n `)
  dispatch(setData(ReducersTypes.SET_INVOICE_LIST, { exporting: true }));
  return instance.get(URL);
};

const getQuery = async (data, URL) => {
  var query = URL;
  data = {
    ...data,
    client_customer_rel_id: get(data, "client_customer_rel_id.id", ""),
  };
  return `${query}${generateQueryString(data)}`;
};

export const createCustomer = (postObj = {}, callback) => async (dispatch) => {
  instance.post("customer", JSON.stringify(postObj))
    .then(() => {
      callback();
    }).catch(e => {
        toast.error("Could not create customer")
        dispatch(setData(ReducersTypes.SET_CUSTOMER_LIST, { showModal: false }));
  })
}

export const deleteCustomers = (ids = [], callback) => async (dispatch) => {
  
  instance.delete("customer",{
    data: JSON.stringify({
      ids: ids
    })
  }).then(() => {
    callback();
  }).catch(() => toast.error("An error occurred"))
}

export const updateCustomer = (id, postObj = {}, callback) => async (dispatch) => {
  instance.put(`customer?id=${id}`, JSON.stringify(postObj))
    .then(() => {
      callback();
    }).catch(e => {
        toast.error("Could not save")
        dispatch(setData(ReducersTypes.SET_CUSTOMER_LIST, { showModal: false }));
    })
}

export const getCustomerList = (params = {}) => async (dispatch) => {
  let URL = await getQuery(params, "customer");

  dispatch(setData(ReducersTypes.SET_CUSTOMER_LIST_FILTER, params));
  dispatch(setData(ReducersTypes.SET_CUSTOMER_LIST, { isFetching: true }));

  instance
    .get(URL)
    .then(async (response) => {
      dispatch(
        setData(ReducersTypes.SET_CUSTOMER_LIST, {
          isFetching: false,
          listData: get(response, "data.result", []),
        })
      );
    })
    .catch((e) => {
      dispatch(
        setData(ReducersTypes.SET_CUSTOMER_LIST, {
          isFetching: false,
        })
      );
      if (e) {
        
      }
    });
  dispatch(
    setData(ReducersTypes.SET_CUSTOMER_LIST_FILTER, {
      showFilter: false,
    })
  );
};

export const resetFilter = () => (dispatch) => {
  dispatch(setData(ReducersTypes.RESET_CUSTOMER_LIST, {}));
};

export const setCustomerData = (data, reducertype) => (dispatch) => {
  dispatch(setData(reducertype, data));
};